import React, { Component } from "react";
import PageLoaded from "../PageLoaded/PageLoaded";

import "./Contact.css";

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            name: 'Contact',
            content: []
        };
    }

    render() {
        return (
            <div className="contact">
                <PageLoaded/>
                <img className="contact-img" src="/img/groupe_canard.png" alt="canards"/>
                <dl>
                    <dd>Adresse</dd>
                    <dt>
                        <ul className="contact-address">
                            <li>7, rue Jeanne d'Arc</li>
                            <li>34725 Saint André de Sangonis</li>
                            <li>France</li>
                        </ul>
                    </dt>
                </dl>
            </div>
        );
    }
}

export default Contact;
