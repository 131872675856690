import React, { Component } from "react";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";

import MenuItem from "./Components/Menu/MenuItem";

import Accueil from "./Components/Accueil/Accueil";
import Contact from "./Components/Contact/Contact";
import Parcours from "./Components/Parcours/Parcours";
import Peintures from "./Components/Peintures/Peintures";
import Reliefs from "./Components/Reliefs/Reliefs";
import Sculptures from "./Components/Sculptures/Sculptures";
import Videos from "./Components/Videos/Videos";

import EE from "wolfy87-eventemitter";

window.EE = new EE();

class App extends Component {
  state = {
    menu: [],
    menu_opened: false,
    selected: "",
    loading: true,
  };

  toggleMenu() {
    this.setState({ menu_opened: !this.state.menu_opened });
  }

  closeMenu() {
    this.setState({ menu_opened: false });
  }

  componentDidMount() {
    const menu = [
      { url: "/accueil", name: "Accueil", label: "Accueil" },
      { url: "/peintures", name: "Peintures", label: "Peintures" },
      { url: "/reliefs", name: "Reliefs", label: "Reliefs" },
      { url: "/sculptures", name: "Sculptures", label: "Sculptures" },
      { url: "/parcours", name: "Parcours", label: "Parcours" },
      { url: "/videos", name: "Videos", label: "Vidéo" },
      { url: "/contact", name: "Contact", label: "Contact" },
    ];

    this.setState({
      menu: menu.map((item) => <MenuItem key={item.name} item={item} onClick={this.closeMenu.bind(this)} />),
      loading: false,
    });

    window.EE.addListener("menu-close", this.closeMenu.bind(this));
  }

  componentWillUnmount() {
    window.EE.removeListener("menu-close", this.closeMenu);
  }

  render() {
    return (
      <Router>
        <div className="app">
          <Route exact path="/" render={() => <Redirect to="/accueil" />} />
          <header className={"header"}>
            <div className="title">
              <h1>Rui Sampaio</h1>
              <div className="button__toggle-menu" onClick={this.toggleMenu.bind(this)}>
                <div className="menu-button"></div>
                <div className="menu-button"></div>
                <div className="menu-button"></div>
                <div>Menu</div>
              </div>
            </div>
          </header>
          <ul className={"menu" + (!this.state.menu_opened ? " menu--closed" : "")}>{this.state.menu}</ul>
          <section className="content">
            <Route path="/accueil" component={Accueil} />
            <Route path="/videos" component={Videos} />
            <Route path="/peintures" component={Peintures} />
            <Route path="/reliefs" component={Reliefs} />
            <Route path="/sculptures" component={Sculptures} />
            <Route path="/parcours" component={Parcours} />
            <Route path="/Contact" component={Contact} />
          </section>
          <footer className="footer">&copy; 2015 Rui Sampaio - Tous droits réservés</footer>
        </div>
      </Router>
    );
  }
}

export default App;
