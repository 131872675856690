import React, { Component } from "react";
import PageLoaded from "../PageLoaded/PageLoaded";

import "./Parcours.css";

class Parcours extends Component {
  constructor() {
    super();
    this.state = {
      name: "Parcours",
      content: [],
    };
  }

  render() {
    return (
      <div className="parcours">
        <PageLoaded />
        <div className="parcours-presentation">
          <div className="image" style={{ backgroundImage: "url('/img/presentation.png')" }}></div>
          <div className="text">
            <p>
              Rui Sampaio partage son enfance entre le Portugal et l’Afrique, puis s’établit à Paris dans les années 80 où il participe à des expositions dans des lieux alternatifs. Autodidacte, le dessin accompagne sa vie au gré des voyages, des rencontres et des lectures. Des petits formats et des
              fragments ébauchés sont recomposés pour envahir l’espace d’un cahier, d’une toile...
            </p>
            <p>La récupération de divers supports lui permet de réaliser des collages et des peintures où les éléments figuratifs s’entrecroisent et semblent se mouvoir.</p>
            <p>De même, les objets de son quotidien sont assemblés pour créer des sculptures parfois peintes et animées dont les volumes varient de la miniature au géant.</p>
          </div>
        </div>

        <div className="parcours-expositions">
          <div className="text">
            <div className="title">Expositions personnelles</div>

            <div className="annee">
              <div className="date">2024</div>
              <ul>
                <li>Un monde composite - Chapelle des pénitents - Aniane 34150</li>
                <li>Ecritures et signes dans l’image – Librairie Le Meilleur des Mondes - Aniane 34150</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2020</div>
              <ul>
                <li>Voyage Singulier – Centre d’Art Contemporain - Bédarieux 34600</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2019</div>
              <ul>
                <li>Transmutation - MJC Monplaisir dans le cadre de la 8BHN - Lyon 69008</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2013</div>
              <ul>
                <li>Espace culturel du CHRU de Montpellier 34000</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2011</div>
              <ul>
                <li>Hors concept - La magie des matériaux - fondation A.N.P.Q - Péret 34800</li>
                <li>Art nomade - Saint-Babel - Le Broc 63500</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2010</div>
              <ul>
                <li>Art Nomade - Le Vernet la Varenne 63580</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2009</div>
              <ul>
                <li>La Baraka - Ste Croix Vallée Française 48110</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2006</div>
              <ul>
                <li>Médiathèque Municipale de Gignac 34150</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2005</div>
              <ul>
                <li>Le Bocal - Lodève 34700</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2003</div>
              <ul>
                <li>Salle d’exposition du Village du Livre - Octon 34800</li>
              </ul>
            </div>
          </div>
          <div className="image" style={{ backgroundImage: "url('/img/expositions.png')" }}></div>
        </div>

        <div className="parcours-expositions">
          <div className="text">
            <div className="title">Expositions collectives</div>

            <div className="annee">
              <div className="date">2023</div>
              <ul>
                <li>Expo Art Singulier - Salon de Baylenque Canaules 30350</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2019</div>
              <ul>
                <li>15ème Semaine de la Peinture Européenne en Occitanie - Maison de l’Europe – Montpellier 34000 et Sète 34200</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2015</div>
              <ul>
                <li>13 ème Festival International d’Art Singulier - Roquevaire 13360</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2013</div>
              <ul>
                <li>Aux sources de la BHN - Espace d'exposition du Centre Hospitalier St Joseph St Luc - Lyon 69007</li>
                <li>Expo BIC - Le silence hurlant - Médiathèque de Gignac 34150</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2012</div>
              <ul>
                <li>Festival Singulièrement Vôtre - MPT Frédéric CHOPIN - Montpellier 34000</li>
                <li>Galerie Otéro - La Colle sur Loup 06480</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2011</div>
              <ul>
                <li>Art nomade à la salle du Fort - Le Broc 6350</li>
                <li>Musée Centre d’Art - L’art en Marche - Lapalisse 03120</li>
                <li>Galerie Singul’ART - Lyon 69001</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2010</div>
              <ul>
                <li>Musée des Arts Buissonniers - Saint Sever du Moustier 12370</li>
                <li>Art nomade - la Halle aux grains - Issoire 63500 - en partenariat avec l’Art en Marche</li>
                <li>Galerie Singul’ART - Lyon 69001</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2009</div>
              <ul>
                <li>5ème semaine de la peinture européenne en Languedoc-Roussillon Maison de l’Europe - Montpellier 34000</li>
                <li>12ème Festival des voix de la méditerranée - Le Bocal - Lodève 34700</li>
                <li>Galerie Vidourle prix - Sauve 30610</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2008</div>
              <ul>
                <li>Les 10 ans de la Médiathèque - Gignac 34150</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Parcours;
